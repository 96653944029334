import React from "react"
import Header from "../components/Header"


export default () => (
  <div style={{ margin: `2rem auto`, maxWidth: 1000 }}>
    <Header />
    <h1 style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '45vh' }}>To be coming soon!</h1>
  </div>
)
